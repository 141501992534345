import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import type { BannerHeroImage } from 'theme/stores/cms/types';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { InnerHTML } from '../../../atom/InnerHTML';
import { Button } from '../../../atom/Button/Button';
import { Link } from '../../../atom/Link/Link';

import type { IHomepageStaticBanner } from './StaticBanner.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Homepage Static Banner
 *
 * Renders an image with custom text (specified by the shop administrator)
 */
@Component({})
export class HomepageStaticBanner extends BaseComponent<IHomepageStaticBanner, unknown> {
  private static cmsPagePathPrefix = '/i/';

  /**
   * An array containing component information (like image path, banner content, etc.)
   */
  @Prop({ type: Object, required: true })
  bannerHeroData: BannerHeroImage;

  /**
   * Layout items length
   */
  @Prop({ type: Array, required: false, default: () => [] })
  layoutItems?: any;

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  @appContextStore.Getter('isTablet')
  private isTablet: boolean;

  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  get mainBannerImageSizes() {
    const imagesConfig = this.extended.$config.zento.images;

    return 'pageXForceFlexWidget' in imagesConfig && this.bannerHeroData.content_position === 'rightCenter'
      ? imagesConfig.pageXForceFlexWidget
      : 'pageXForceFlexSecondWidget' in imagesConfig && this.bannerHeroData.content_position === 'twoColumns'
      ? imagesConfig.pageXForceFlexSecondWidget
      : imagesConfig.staticMainBanner.layout === 'layout3' && this.bannerHeroData.content_position === 'leftOverImage' // TODO: should be removed once widget provides all necessary information
      ? imagesConfig.bannerSecondaryImage
      : this.$route.path.indexOf(HomepageStaticBanner.cmsPagePathPrefix) > -1 && !this.$route.path.includes('home')
      ? this.bannerHeroData.isSlider && 'cmsPageSliderBanner' in imagesConfig
        ? imagesConfig.cmsPageSliderBanner
        : imagesConfig.cmsPageStaticMainBanner
      : this.bannerHeroData.isSlider && 'sliderBanner' in imagesConfig
      ? imagesConfig.sliderBanner
      : imagesConfig.staticMainBanner;
  }

  render() {
    const image = {
      src: this.bannerHeroData.image,
      loading: '',
    };
    const imagesConfig = this.extended.$config.zento.images;
    const config = imagesConfig.staticMainBanner;
    const layout = config.layout;
    const bannerConfig = this.extended.$config.zento.theme.banner;

    return (
      <section
        class={{
          [style.bannerWrapper]: true,
          [style.layout1]: layout === 'layout1',
          [style.layout2]: layout === 'layout2',
          [style.layout3]: layout === 'layout3',
          [style.layout4]: layout === 'layout4',
          [style.layout5]: layout === 'layout5',
          [style.layout6]: layout === 'layout6',
          [style.layout7]: layout === 'layout7',
          [style.layout8]: layout === 'layout8',
          [style.layout9]: layout === 'layout9',
          [style.layout10]: layout === 'layout10',
          [style.layout12]: layout === 'layout12',
          [style.leftBottom]: this.bannerHeroData.content_position === 'leftBottom',
          [style.leftTop]: this.bannerHeroData.content_position === 'leftTop',
          [style.leftCenter]: this.bannerHeroData.content_position === 'leftCenter',
          [style.centerBottom]: this.bannerHeroData.content_position === 'centerBottom',
          // Temporary class added to accommodate client needs
          [style.homeCenterBottom]:
            this.extended.$config.zento.theme.header.hideLogo &&
            (this.$route.path === '/' || (this.$route.meta && this.$route.meta.originalPath === '/')) &&
            this.bannerHeroData.content_position === 'centerBottom',
          [style.centerTop]: this.bannerHeroData.content_position === 'centerTop',
          [style.center]: this.bannerHeroData.content_position === 'center',
          [style.rightBottom]: this.bannerHeroData.content_position === 'rightBottom',
          [style.rightTop]: this.bannerHeroData.content_position === 'rightTop',
          [style.rightCenter]: this.bannerHeroData.content_position === 'rightCenter',
          [style.leftOverImage]: this.bannerHeroData.content_position === 'leftOverImage',
          [style.rightOverImage]: this.bannerHeroData.content_position === 'rightOverImage',
          [style.topOverImage]: this.bannerHeroData.content_position === 'topOverImage',
          [style.bottomOverImage]: this.bannerHeroData.content_position === 'bottomOverImage',
          [style.twoColumns]: this.bannerHeroData.content_position === 'twoColumns',
          [style.twoColumnsAlignTextLeft]:
            this.bannerHeroData.content_position === 'twoColumns' && this.bannerHeroData.textPosition === 'left',
          [style.twoColumnsAlignTextRight]:
            this.bannerHeroData.content_position === 'twoColumns' && this.bannerHeroData.textPosition === 'right',
          [style.smallContainer]: this.bannerHeroData.width === 'small',
          [style.largeContainer]: this.bannerHeroData.width === 'large',
          [style.fullContainer]: this.bannerHeroData.width === 'full_width',
          [style.twoThirds]: this.bannerHeroData.content_position === '25_75',
          [style.twoThirdsAlignTextLeft]:
            this.bannerHeroData.content_position === '25_75' && this.bannerHeroData.textPosition === 'left',
          [style.twoThirdsAlignTextRight]:
            this.bannerHeroData.content_position === '25_75' && this.bannerHeroData.textPosition === 'right',
          [style.gradient]: this.bannerHeroData.gradient,
        }}
        key={this.bannerHeroData.button_label}>
        <div class={style.bannerBox}>
          <div class={style.bannerItem} key='banner-item'>
            <MainImage
              image={image}
              folder=''
              // TODO: Change this values once page builder dimensions are available from page builder
              width={this.layoutItems.length > 3 && layout === 'layout2' ? 321 : this.mainBannerImageSizes.width}
              height={this.layoutItems.length > 3 && layout === 'layout2' ? 470 : this.mainBannerImageSizes.height}
              tabletWidth={this.layoutItems.length > 3 ? 850 : this.mainBannerImageSizes.tabletWidth}
              tabletHeight={this.layoutItems.length > 3 ? 637 : this.mainBannerImageSizes.tabletHeight}
              desktopWidth={this.layoutItems.length > 3 ? 850 : this.mainBannerImageSizes.desktopWidth}
              desktopHeight={this.layoutItems.length > 3 ? 637 : this.mainBannerImageSizes.desktopHeight}
              heightFallback={
                imagesConfig.pageXForceFlexWidget?.resizeAndHeightFallback ||
                imagesConfig.pageXForceFlexSecondWidget?.resizeAndHeightFallback ||
                (this.bannerHeroData.content_position === 'twoColumns' && this.isTablet) ||
                (!this.isDesktop && layout === 'layout10') ||
                layout === 'layout1' ||
                (layout === 'layout6' && config.resizeAndHeightFallback)
                  ? null
                  : !this.isMobile || layout === 'layout9'
                  ? 0
                  : null
              }
              resize={
                imagesConfig.pageXForceFlexWidget?.resizeAndHeightFallback ||
                imagesConfig.pageXForceFlexSecondWidget?.resizeAndHeightFallback ||
                (this.bannerHeroData.content_position === 'twoColumns' && this.isTablet) ||
                (this.isMobile && layout !== 'layout2' && this.isMobile && layout !== 'layout9') ||
                (this.isMobile && this.layoutItems.length > 3 && layout === 'layout2') ||
                (!this.isDesktop && layout === 'layout10') ||
                layout === 'layout1' ||
                (layout === 'layout6' && config.resizeAndHeightFallback)
                  ? 'xc'
                  : 'xf'
              }
              loadingType='eager'
              class={style.mainBannerImg}
              key='static-banner-img'
            />
            <div class={style.container} key='static-banner-content'>
              {this.bannerHeroData.content || this.bannerHeroData.button_label || this.bannerHeroData.has_link ? (
                <div class={style.bannerContent}>
                  <div class={style.bannerContentBox}>
                    {this.bannerHeroData.content ? (
                      <div class={style.bannerBox} key='static-banner-content-text'>
                        <InnerHTML
                          contents={
                            this.isMobile && !bannerConfig.showFullText
                              ? decodePhpHtmlString(this.bannerHeroData.content.slice(0, bannerConfig.textLength))
                              : decodePhpHtmlString(this.bannerHeroData.content)
                          }
                        />
                      </div>
                    ) : null}
                    {this.bannerHeroData.button_label ? (
                      <Button
                        class={style.btnBanner}
                        styleType={
                          this.bannerHeroData.button_style ?? this.extended.$config.zento.staticBanner.buttonStyle
                        }
                        handler={() => this.$router.push(this.extended.localizedRoute(this.bannerHeroData.button_link))}
                        key='static-banner-content-link'>
                        <span>{this.bannerHeroData.button_label}</span>
                      </Button>
                    ) : this.bannerHeroData.has_link && this.bannerHeroData.link_to ? (
                      <Link to={this.bannerHeroData.link_to} styleType={this.bannerHeroData.link_style}>
                        <span>{this.bannerHeroData.link_label}</span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>

            {this.bannerHeroData.button_link ? (
              this.bannerHeroData.button_link.startsWith('/media') ||
              this.bannerHeroData.button_link.startsWith('media') ? (
                <a
                  href={
                    this.extended.$config.zento.theme.storeData.storeUrl +
                    (this.bannerHeroData.button_link.startsWith('media') ? '/' : '') +
                    this.bannerHeroData.button_link
                  }
                  rel='noopener'
                  target='_blank'
                />
              ) : this.bannerHeroData.button_link.startsWith('http') ||
                this.bannerHeroData.button_link.startsWith('www') ? (
                <a href={this.bannerHeroData.button_link} rel='noopener' target='_blank' />
              ) : (
                <Link to={this.bannerHeroData.button_link} />
              )
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
